<template>
  <div class="sb_view_components">
    <div class="sb_view_components_container">
      <sb-spacer height="80" />
      <h1>
        h1 - Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis
        vestibulum.
      </h1>
      <h2>
        h2 - Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis
        vestibulum.
      </h2>
      <h3>
        h3 - Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis
        vestibulum.
      </h3>
      <h4>
        h4 - Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis
        vestibulum.
      </h4>
      <h5>
        h5 - Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis
        vestibulum.
      </h5>
      <h6>
        h6 - Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis
        vestibulum.
      </h6>
      <p>
        p - Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis
        vestibulum.
      </p>
      <sb-spacer height="80" />

      <hr />

      <sb-spacer height="80" />

      <h2>Buttons</h2>

      <p>
        button-type (String): <code>primary</code> <code>light</code>
        <code>black</code>
      </p>
      <sb-button>Primary</sb-button>
      <sb-button button-type="light"> Light </sb-button>
      <sb-button button-type="light-black"> Light black </sb-button>
      <sb-button button-type="black"> Black </sb-button>
      <sb-button button-type="white"> White </sb-button>
      <sb-button button-type="red"> Red </sb-button>

      <br />
      <br />

      <sb-button>
        Primary
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>

      <sb-button button-type="light">
        Light
        <sb-icon icon-id="icon_info" />
      </sb-button>
      <sb-button button-type="light-black">
        Light black
        <sb-icon icon-id="icon_info" />
      </sb-button>
      <sb-button button-type="black">
        Black
        <sb-icon icon-id="icon_info" />
      </sb-button>
      <sb-button button-type="white">
        White
        <sb-icon icon-id="icon_info" />
      </sb-button>
      <sb-button button-type="red">
        Red
        <sb-icon icon-id="icon_info" />
      </sb-button>

      <sb-spacer height="40" />

      <p>ghost (Data attribute):</p>
      <sb-button button-type="primary" ghost> Primary ghost </sb-button>
      <sb-button button-type="light" ghost> Light ghost </sb-button>
      <sb-button button-type="light-black" ghost> Light-black ghost </sb-button>
      <sb-button button-type="black" ghost> Black ghost </sb-button>
      <br />
      <br />
      <sb-button button-type="primary" ghost>
        Primary ghost icon
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <sb-button button-type="light" ghost>
        Light ghost icon
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <sb-button button-type="black" ghost>
        Black ghost icon
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>

      <sb-spacer height="40" />

      <p>naked (Data attribute):</p>
      <sb-button button-type="primary" naked> Primary naked </sb-button>
      <sb-button button-type="light" naked> Light naked </sb-button>
      <sb-button button-type="light-black" naked> Light black naked </sb-button>
      <sb-button button-type="black" naked> Black naked </sb-button>

      <br />
      <br />
      <p>
        collapse options (Data attribute): <code>collapsed</code>
        <code>collapsed-left</code> <code>collapsed-right</code>
      </p>
      <sb-button button-type="primary" naked collapsed>
        Primary naked
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <sb-button button-type="primary" naked collapsed-left>
        Primary naked
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <sb-button button-type="primary" naked collapsed-right>
        Primary naked
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>

      <sb-spacer height="40" />

      <p>
        size (String): <code>large</code> <code>regular</code>
        <code>small</code>
      </p>
      <sb-button size="large">
        Small
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>

      <sb-button>
        Regular
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>

      <sb-button size="small">
        Small
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>

      <sb-spacer height="40" />
      <sb-button size="large" :loading="true">
        Small
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>

      <sb-button :loading="true">
        Regular
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>

      <sb-button size="small" :loading="true">
        Small
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>

      <sb-spacer height="40" />

      <p>Icons left and reight are determined by the position in the HTML</p>
      <sb-button>
        <sb-icon icon-id="icon_arrow-left" />
        Icon left
      </sb-button>

      <sb-button>
        Icon right
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>

      <sb-spacer height="40" />

      <p>icon_only (calculated)</p>
      <sb-button :loading="true" />
      <sb-button>
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <sb-button button-type="light">
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <sb-button button-type="black">
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <sb-button ghost>
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <sb-button ghost button-type="light">
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <sb-button ghost button-type="black">
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <sb-button squarcle>
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <sb-button button-type="light" squarcle>
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <sb-button button-type="black" squarcle>
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <sb-button ghost squarcle>
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <sb-button button-type="light" ghost squarcle>
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <sb-button button-type="black" ghost squarcle>
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>

      <br />
      <br />

      <sb-button size="small">
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <sb-button size="small" button-type="light">
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <sb-button size="small" button-type="black">
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <sb-button size="small" ghost>
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <sb-button size="small" ghost button-type="light">
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <sb-button size="small" ghost button-type="black">
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <sb-button size="small" squarcle>
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <sb-button size="small" button-type="light" squarcle>
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <sb-button size="small" button-type="black" squarcle>
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <sb-button size="small" ghost squarcle>
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <sb-button size="small" button-type="light" ghost squarcle>
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <sb-button size="small" button-type="black" ghost squarcle>
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>

      <sb-spacer height="40" />

      <p>loading (Boolean) <i>text and icon will be hidden</i></p>
      <sb-button :loading="true">
        Opslaan
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <sb-button button-type="light" :loading="true">
        Opslaan
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <sb-button button-type="black" :loading="true">
        Opslaan
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>

      <br />
      <br />

      <sb-button size="small" :loading="true">
        Opslaan
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <sb-button button-type="light" size="small" :loading="true">
        Opslaan
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <sb-button button-type="black" size="small" :loading="true">
        Opslaan
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>

      <sb-spacer height="40" />

      <p>expand (Data attribute) <i>grow to available width</i></p>
      <sb-button expand>
        <sb-icon icon-id="icon_arrow-right" />
        Icon left
      </sb-button>

      <sb-spacer height="10" />

      <sb-button expand>
        Icon right
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>

      <sb-spacer height="40" />

      <p>
        expand-on-small (Data attribute)
        <i>grow to available width, only on small devices</i>
      </p>

      <button expand>
        <sb-button expand-on-small icon-animation-direction="left">
          <sb-icon icon-id="icon_arrow-left" />
          Icon left
        </sb-button>
      </button>

      <p>
        expand-medium-portrait-down (Data attribute)
        <i>grow to available width, only on medium-portrait and down devices</i>
      </p>

      <button expand>
        <sb-button expand-medium-portrait-down icon-animation-direction="left">
          <sb-icon icon-id="icon_arrow-left" />
          Icon left
        </sb-button>
      </button>

      <sb-spacer height="10" />
      <button expand>
        <sb-button expand-on-small icon-animation-direction="right">
          Icon right
          <sb-icon icon-id="icon_arrow-right" />
        </sb-button>
      </button>

      <sb-spacer height="40" />

      <p>
        icon-animation (Data attribute)
        <i>set an animation of the icon on hover.</i>
      </p>
      <p>
        values: <code>top</code> <code>right</code> <code>down</code>
        <code>bottom</code>
      </p>
      <button>
        <sb-button icon-animation-direction="right">
          Icon animation right
          <sb-icon icon-id="icon_arrow-right" />
        </sb-button>
      </button>

      <br />
      <br />
      <p>
        disabled (Data attribute)
        <i>set disabled state</i>
      </p>
      <sb-button disabled>
        Primary
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <sb-button disabled button-type="light">
        Light
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <sb-button disabled button-type="light-black">
        Light black
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <sb-button disabled button-type="black">
        Black
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <br />
      <br />
      <sb-button disabled ghost>
        Primary
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>

      <sb-button disabled ghost button-type="light">
        Light
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <sb-button disabled ghost button-type="light-black">
        Light black
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <sb-button disabled ghost button-type="black">
        Black
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <br />
      <br />
      <sb-button disabled naked>
        Primary
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <sb-button disabled naked button-type="light">
        Light
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <sb-button disabled naked button-type="light-black">
        Light black
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>
      <sb-button disabled naked button-type="black">
        Black
        <sb-icon icon-id="icon_arrow-right" />
      </sb-button>

      <sb-spacer height="80" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.sb_view_components {
  margin: 0 auto;
  max-width: 1200px;
  padding: 4rem 0;
}
</style>
