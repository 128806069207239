<template>
  <div class="sb_view_login">
    <sb-logo-full class="sb_view_login_logo" />
    <i-form
      ref="formLogin"
      class="sb_view_login_content"
      :model="formLogin"
      :rules="validateFormLogin"
      label-position="top"
      @submit.native.prevent="handleSubmit('formLogin')"
    >
      <div class="sb_view_login_content_top">
        <h1>Inloggen</h1>
        <sb-spacer height="20" />

        <form-item label="Gebruikersnaam" prop="username" style="padding: 0">
          <i-input
            v-model="formLogin.username"
            placeholder="Voer je gebruikersnaam in"
            name="sb-technisch-lezen-username"
            autocomplete="username"
          />
        </form-item>

        <form-item label="Wachtwoord" prop="password">
          <i-input
            v-model="formLogin.password"
            type="password"
            name="sb-technisch-lezen-password"
            autocomplete="current-password"
          />
        </form-item>
      </div>
      <div class="sb_view_login_content_bottom">
        <div class="sb_view_login_content_bottom_left">
          <i-button
            :loading="$store.state.loading"
            type="primary"
            size="large"
            html-type="submit"
          >
            Inloggen
          </i-button>
        </div>
        <div class="sb_view_login_content_bottom_right">
          <router-link :to="{ name: 'ForgotPassword' }">
            Wachtwoord vergeten
          </router-link>
          <router-link :to="{ name: 'ForgotUsername' }">
            Gebruikersnaam vergeten
          </router-link>
        </div>
      </div>
    </i-form>
  </div>
</template>

<script>
import SbLogoFull from '@/components/SbLogoFull';

import { onLogin } from '@/vue-apollo';
import { getExceptionName, getExceptionResponse } from '@/lib/util-helper';

import Login from '@/graphql/mutations/Login.gql';
import { setCurrentUser } from '@/lib/user-management';
import tourDataMixin from '@/mixins/tourDataMixin';

export default {
  name: 'Login',
  components: {
    SbLogoFull,
  },
  mixins: [tourDataMixin],
  data() {
    return {
      formLogin: {
        username: '',
        password: '',
      },
      validateFormLogin: {
        username: [
          {
            required: true,
            message: 'Voer een gebruikersnaam in',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: 'Voer een wachtwoord in',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  mounted() {
    console.log(this.$user.roleContext);
  },
  methods: {
    async login() {
      this.$store.state.loading = true;
      try {
        const {
          data: {
            login: { accessToken },
          },
        } = await this.$apollo.mutate({
          mutation: Login,
          variables: {
            input: {
              username: this.formLogin?.username.toLowerCase(),
              password: this.formLogin.password,
            },
          },
        });
        if (!accessToken) return false;
        await onLogin(this.$apollo.provider.defaultClient, accessToken);
        await this.getOnboardingSettings();
        setCurrentUser(
          this.formLogin.username,
          this.$user.role,
          accessToken,
          this.$user.id,
        );

        console.log('this$user', this.$user);
        console.log('logged in...', this.$user.role);
        console.log('role', this.$user.role);

        // If it's admin, redirect to admin dashboard
        if (this.$user.role === 'ADMIN') {
          return this.$router.push({
            name: 'Resource',
            params: {
              role: this.$user.role?.toLowerCase() || null,
              resourceType: 'admin',
              resourceId: 'default',
            },
          });
        }

        if (
          [
            this.$user.role,
            this.$user.resourceType,
            this.$user.resourceId,
          ].every(Boolean)
        ) {
          return this.$router.push({
            name: 'Resource',
            params: {
              role: this.$user.role.toLowerCase(),
              resourceType: this.$user.resourceType.toLowerCase(),
              resourceId: this.$user.resourceId,
            },
          });
        }

        return this.$router.push({
          name: 'SystemMaintainerDictionary',
        });
      } catch (e) {
        console.error(e);
        if (getExceptionName(e) === 'TemporaryPasswordInUseException') {
          const exceptionResponse = getExceptionResponse(e);
          this.$router.push({
            name: 'NewPassword',
            query: {
              token: exceptionResponse.passwordResetToken,
              username: this.formLogin.username,
            },
          });
          console.log('Tijdelijk wachtwoord');
        } else {
          console.log('should throw error');
          throw new Error(e);
        }
      } finally {
        this.$store.state.loading = false;
      }
    },
    handleSubmit(name) {
      this.$refs[name].validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.login();
      });
    },
  },
};
</script>

<style lang="scss">
.sb_view_login {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: $brand-lightest-gray;
  background-image: url('/static/img/bg/leeshelden.png');
  background-repeat: no-repeat;
  background-position: calc(50% - -140px) center;

  .ivu-form-item {
    padding: 0 !important;
  }

  &_logo {
    padding: 100px 0 40px 0;

    @media screen and (min-height: 800px) {
      padding: 14vh 0 40px 0;
    }
  }

  &_content {
    width: 100%;
    max-width: 600px;
    background: $brand-white;
    border-radius: 10px;
    box-shadow: 0 0 30px rgba($brand-black, 0.1),
      0 5px 5px rgba($brand-black, 0.1);

    &_top {
      padding: 40px;
    }

    &_bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 30px 40px;
      background: $brand-lightest-gray;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;

      &_left {
      }

      &_right {
        text-align: right;

        > a {
          display: block;
        }
      }
    }
  }
}
</style>
